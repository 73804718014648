<template>
  <v-container id="about">
    <h1 class="mt-12">利用規約</h1>
    <p>
      この利用規約は、運営会社である株式会社エヌアイデイ(以下「当社」）が本サイト上で提供するサービス(以下「本サービス」)の利用条件を定めるものです。本サイトご利用者様(以下「ユーザー」)は利用規約に従って本サービスをご利用いただきます。
    </p>
    <ul class="about-linklist">
      <li class="text-right">
        <a
          href="https://www.nid.co.jp"
          target="_blank"
          rel="noopener noreferrer"
          >{{ $t("menu.company") }}</a
        >
      </li>
      <li class="text-right">
        <a v-bind="email_address">{{ $t("menu.contact") }}</a>
      </li>
    </ul>

    <section class="mt-4">
      <h2 class="mb-2">アップロード画像および変換画像について</h2>
      <p>
        当社の<a href="https://www.nid.co.jp/privacy" target="_blank" rel="noopener noreferrer">個人情報保護方針</a>に従い適切に取り扱うものとします。また、画像データをサーバーやその他のメディアに保存することはありません。
      </p>
    </section>

    <section class="mt-4">
      <h2 class="mb-2">禁止事項</h2>
      <p class="mt-2">
        変換画像については以下の利用を禁止しております。ユーザーが下記の一にでも該当する行為を行ったことにより当社が損害を受けた場合、当社はユーザーに対して損害の賠償を求めることができます。
      </p>
      <ul>
        <li>公序良俗に反する行為</li>
        <li>犯罪行為もしくは犯罪の恐れのある行為</li>
        <li>他人の著作権を侵害する行為</li>
        <li>他人の財産、プライバシー等を侵害する行為</li>
        <li>他人の名誉を毀損しあるいは誹謗中傷する行為</li>
        <li>その他法令に違反する行為</li>
        <li>本サイトの運営を妨害するおそれのある行為</li>
        <li>当社の信頼を毀損する行為</li>
        <li>その他、当社が不適切と判断する行為</li>
      </ul>
    </section>

    <section class="mt-4">
      <h2 class="mb-2">利用規約の変更</h2>
      <p>
        当社は、ユーザーに事前に通知することなくいつでも本規約を変更することができるものとします。ユーザーは、本規約を変更することおよび変更後の規約について、あらかじめ同意したものとみなします。
      </p>
    </section>

    <section class="mt-4">
      <h2 class="mb-2">免責</h2>
      <p>
        ユーザーが、本サービスおよび本サービスにより得られた変換画像の利用に関連して第三者に対して損害を与えた場合、当該ユーザーの負担と責任において解決することとし、当社は一切の責任を負いません。
      </p>
    </section>

    <section class="mt-4">
      <h2 class="mb-2">サイトポリシー</h2>
      <p>
        その他事項は、当社の<a href="https://www.nid.co.jp/site-policy/" target="_blank" rel="noopener noreferrer">サイトポリシー</a>に従うものといたします。
      </p>
    </section>
  </v-container>
</template>

<style>
.about-linklist {
  list-style-type: none;
}
</style>

<script>
export default {
  name: "About",
  data: () => ({
    email_address: {
      href: "mailto:" + process.env.VUE_APP_EMAIL,
    },
  })
};
</script>
